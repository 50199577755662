import React, {ChangeEvent, useEffect, useState} from 'react';
import { FiSearch, FiXCircle } from 'react-icons/fi';
import {
    Box,
    FormControl,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    List,
    ListItem,
    Select,
    theme,
} from '@chakra-ui/react';
import { pastSeasons, useSeasonYear } from '../../../pages/hooks/SeasonYearContext';
import { PastSeason, Team } from "../../../utils/apiTypes";
import useApi from '../../../pages/hooks/useApi';
import {Link, useLocation} from 'react-router-dom';

const TeamSearchDropdown: React.FC = () => {
    const { selectedYear, setSelectedYear, gender, setGender, getSeasonId } = useSeasonYear();
    const routerLocation = useLocation();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);

    useEffect(() => {
        const pathSegments = routerLocation.pathname.split('/');
        const detectedGender = pathSegments.includes('women') ? 'women' : 'men';

        if (detectedGender !== gender) {
            setGender(detectedGender);
        }
    }, [routerLocation.pathname, setGender]);

    // Obtain the seasonId based on current selectedYear and gender
    const seasonId = getSeasonId(selectedYear, gender);

    const { data: teams } = useApi<Team[]>(`api/v1/client/10/teams/search?seasonId=${seasonId}&name=${searchTerm}`);

    const clearSearch = () => {
        setSearchTerm('');
        setShowAutoComplete(false);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (value && Array.isArray(teams)) {
            setShowAutoComplete(true);
            const filtered = teams.filter(team =>
                team.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredTeams(filtered);
        } else {
            setShowAutoComplete(false);
            setFilteredTeams([]);
        }
    };

    const handleGenderChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedGender = event.target.value as 'men' | 'women';
        setGender(selectedGender);
    };

    const handleYearChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const year = event.target.value ? Number(event.target.value) : null;
        setSelectedYear(year); // Set to null when "Current Season" is selected
    };

    return (
        <Box width="300px" margin="0 auto" position="relative" zIndex={1}>
            <InputGroup maxW="2xs" display="inline-flex">
                {showAutoComplete ? (
                    <InputLeftElement cursor="pointer">
                        <Icon as={FiXCircle} color="fg.accent" fontSize="lg" onClick={clearSearch} />
                    </InputLeftElement>
                ) : (
                    <InputLeftElement>
                        <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />
                    </InputLeftElement>
                )}

                <Input
                    placeholder="Search teams"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    borderRadius="10px 0px 0px 10px"
                    borderRight="0px"
                />
                <Select
                    color={theme.colors.white}
                    bg="bg.accent.default"
                    borderRadius="0px 10px 10px 0px"
                    borderLeft="1px solid"
                    value={gender}
                    onChange={handleGenderChange}
                >
                    <option value="men">Boys</option>
                    <option value="women">Girls</option>
                </Select>
            </InputGroup>

            <FormControl mt={2}>
                <Select
                    onChange={handleYearChange}
                    value={selectedYear === null ? "" : selectedYear}
                    color={theme.colors.white}
                    bg="bg.accent.default"
                    borderRadius="10px 10px 10px 10px"
                    width="260px"
                >
                    <option value="">Current Season</option>
                    {pastSeasons.map((season: PastSeason) => (
                        <option key={season.year} value={season.year}>
                            {season.year}
                        </option>
                    ))}
                </Select>
            </FormControl>

            {showAutoComplete && (
                <List maxHeight="80vh" overflowY="scroll" width="100%" position="absolute" top="34px" backgroundColor="white" boxShadow="md" zIndex={999}>
                    {filteredTeams.map((team) => (
                        <Link to={`/team/${gender}/${team.id}`} key={team.id}>
                            <ListItem _hover={{ bg: "gray.200" }} onClick={clearSearch} paddingX="4" paddingY="2">
                                {team.name}
                            </ListItem>
                        </Link>
                    ))}
                </List>
            )}
        </Box>
    );
};

export default TeamSearchDropdown;


