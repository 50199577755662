import React, { createContext, useContext, ReactNode } from 'react';
import { Season } from "../../utils/apiTypes";

interface SeasonContextProps {
    season?: Season | null; // Allow season to be Season, null, or undefined
    setSeason: (season: Season | null) => void; // Allow setSeason to accept null
}

const SeasonContext = createContext<SeasonContextProps | undefined>(undefined);

interface SeasonProviderProps {
    children: ReactNode;
}

export const SeasonProvider: React.FC<SeasonProviderProps> = ({ children }) => {
    const initialSeason = localStorage.getItem('seasonData')
        ? JSON.parse(localStorage.getItem('seasonData') as string)
        : null; // Set as null if there's no data in localStorage
    const [season, setSeasonState] = React.useState<Season | null>(initialSeason);

    const setSeason = (season: Season | null) => { // Adjust setSeason to accept null
        if (season) {
            localStorage.setItem('seasonData', JSON.stringify(season));
        } else {
            localStorage.removeItem('seasonData'); // Clear localStorage if season is null
        }
        setSeasonState(season);
    };

    return (
        <SeasonContext.Provider value={{ season, setSeason }}>
            {children}
        </SeasonContext.Provider>
    );
};

export const useSeason = (): SeasonContextProps => {
    const context = useContext(SeasonContext);
    if (!context) {
        throw new Error("useSeason must be used within a SeasonProvider");
    }
    return context;
};
