// TeamSchedule.tsx

import React, { useState } from 'react';
import { Box, Flex, Heading, InputGroup, SimpleGrid, theme } from '@chakra-ui/react';
import TeamScheduleCard from './TeamScheduleCard';
import useApi from '../hooks/useApi';
import { IPaginatedSchedule } from '../../utils/apiTypes';
import Pagination from './Pagination';
import DivisionFilter from './DivisionFilter';
import DatePicker from './DatePicker';
import { GenderLinks } from './GenderLinks';
import { useSeasonYear } from "../hooks/SeasonYearContext";
import { useParams } from 'react-router-dom';

const smsLinkStyle = {
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer'
};

const TeamSchedule: React.FC = () => {
    const { gender, selectedYear, getSeasonId } = useSeasonYear();
    const { id: teamId } = useParams<{ id: string }>();
    const [teamGroup, setTeamGroup] = useState(-1);
    const [page, setPage] = useState(0);
    const [selectedDate, setSelectedDate] = useState('');

    // Calculate seasonId using getSeasonId
    const seasonId = getSeasonId(selectedYear, gender);

    // Include teamId in the API call if it exists
    const { data: schedule, loading } = useApi<IPaginatedSchedule>(
        `api/v1/client/10/games?seasonId=${seasonId}&gender=${gender}&size=25&sort=date&sort=time` +
        `${selectedDate ? `&filterDate=${selectedDate}` : ''}` +
        `${teamGroup !== -1 ? `&teamGroupId=${teamGroup}` : ''}` +
        `${teamId ? `&teamId=${teamId}` : ''}` +
        `&page=${page}`
    );

    if (loading) {
        return <>Loading...</>;
    }

    // Add a check to see if `schedule` and `schedule.content` are defined
    if (!schedule || !schedule.content) {
        return <Box textAlign="center"><div>No results</div></Box>;
    }

    return (
        <>
            <Box>
                <a href="sms:+9785523955" style={smsLinkStyle}>Text final scores to:</a> 978-552-3955
            </Box>
            <Box marginTop={4}>
                ** To see <b>MIAA Tournament</b> game results, click the League, Division, or Playoff Bracket dropdown and select which tournament you want to view.
            </Box>
            <Heading as="h1" fontSize="30px">Schedule</Heading>

            <InputGroup mb={5} gap="20px" padding={4} backgroundColor={theme.colors.gray[300]}>
                <Flex direction={["column", "row"]} justify="left" align="left">
                    <Box width={["100%", "20%"]} p={4} style={{ verticalAlign: 'top' }}>
                        <GenderLinks basePath="/schedules" />
                    </Box>
                    <Box width={["100%", "40%"]} p={4} style={{ verticalAlign: 'top' }}>
                        <DivisionFilter setter={setTeamGroup} selectedValue={teamGroup} />
                    </Box>
                    <Box width={["100%", "30%"]} p={4} style={{ verticalAlign: 'top' }}>
                        <DatePicker setDate={setSelectedDate} selectedDate={selectedDate} />
                    </Box>
                </Flex>
            </InputGroup>

            {schedule.content.length > 0 ? (
                <SimpleGrid columns={1} spacing={{ base: 4, md: 2 }}>
                    {schedule.content.map((game, index) => (
                        <TeamScheduleCard
                            key={index}
                            gameDate={game.date}
                            gameTime={game.time}
                            location={game.location}
                            team1Name={game.homeTeam}
                            team1Id={game.homeTeamId}
                            team1Score={game.homeTeamScore || 0}
                            team2Name={game.awayTeam}
                            team2Id={game.awayTeamId}
                            team2Score={game.awayTeamScore || 0}
                            status={game.status}
                            notes={game.notes}
                            gender={gender}
                        />
                    ))}
                    <Pagination currentPage={page} totalPages={schedule.totalPages} onPageChange={setPage} />
                </SimpleGrid>
            ) : (
                <Box textAlign="center"><div>No results</div></Box>
            )}
        </>
    );
};

export default TeamSchedule;




