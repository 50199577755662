
// useGenderParams.ts
import { useSeasonYear } from './SeasonYearContext';

// Map for season IDs by year and gender
const seasonMap = {
    current: {
        men: parseInt(process.env.REACT_APP_BOYS_SEASON_ID!),
        women: parseInt(process.env.REACT_APP_GIRLS_SEASON_ID!),
    },
    pastSeasons: {
        2024: { men: 12, women: 13 },
        // Add more years as needed
    } as Record<number, { men: number; women: number }>, // Specify Record<number, {...}>
};

const useGenderParams = () => {
    const { selectedYear, gender } = useSeasonYear(); // Updated to SeasonYear context
    const seasonId = selectedYear
        ? seasonMap.pastSeasons[selectedYear]?.[gender]
        : seasonMap.current[gender];

    return { key: seasonId, title: gender };
};

export default useGenderParams;
