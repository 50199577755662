// SeasonYearContext.ts
// SeasonYearContext.ts

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PastSeason {
    year: number;
    boysSeasonId: number;
    girlsSeasonId: number;
}

const pastSeasons: PastSeason[] = [
    { year: 2024, boysSeasonId: 12, girlsSeasonId: 13 },
];

export { pastSeasons };

interface SeasonYearContextProps {
    seasonId: number | null;
    selectedYear: number | null;
    gender: 'men' | 'women';
    setGender: (gender: 'men' | 'women') => void;
    setSelectedYear: (year: number | null) => void;
    getSeasonId: (year: number | null, gender: 'men' | 'women') => number | null;
}

const SeasonYearContext = createContext<SeasonYearContextProps | undefined>(undefined);

export const SeasonYearProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [gender, setGender] = useState<'men' | 'women'>('men');

    const getSeasonId = (year: number | null, gender: 'men' | 'women'): number | null => {
        if (year) {
            const season = pastSeasons.find(s => s.year === year);
            return season ? (gender === 'men' ? season.boysSeasonId : season.girlsSeasonId) : null;
        }
        return gender === 'men'
            ? parseInt(process.env.REACT_APP_BOYS_SEASON_ID!)
            : parseInt(process.env.REACT_APP_GIRLS_SEASON_ID!);
    };

    const seasonId = getSeasonId(selectedYear, gender);

    return (
        <SeasonYearContext.Provider value={{ seasonId, selectedYear, setSelectedYear, gender, setGender, getSeasonId }}>
            {children}
        </SeasonYearContext.Provider>
    );
};

export const useSeasonYear = () => {
    const context = useContext(SeasonYearContext);
    if (!context) throw new Error("useSeasonYear must be used within a SeasonYearProvider");
    return context;
};


