import {
    Button,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    IconButton,
    LinkOverlay,
    LinkBox,
    Text, Flex, Input
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import DeleteConfirmation from "../../Utility/DeleteConfirmation";
import {Game, Page, Season} from "../../../utils/apiTypes";
import {EditIcon, StarIcon, TimeIcon} from "@chakra-ui/icons";
import DeleteConfirmationIcon from "../../Utility/DeleteConfirmationIcon";
import {convertTo12HourTime } from "../../../utils/timeUtility"
import {useAuth} from "../../Auth/AuthContext";
import {apiCall} from "../../../utils/apiUtility";
import {gamesQueryMap} from "../../../utils/queryMapUtil";
import {useClient} from "../../Contexts/ClientContext";
import {useSeason} from "../../Contexts/SeasonContext";
import {useOrganization} from "../../Contexts/OrganizationContext";
import {format, parseISO} from 'date-fns';
function GameList({
                        games,
                        onDelete,
                        onEdit,
                        onView,
                        onInitialize,
                        onShowScoreSheet,
                        onSort,
                        onPageChange,
                        currentPage,
                        totalPages
                    }: {
    games: Game[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onView: (id: number) => void;
    onInitialize: (id: number) => void;
    onShowScoreSheet: (id: number) => void;
    onSort: (newSortField: string[]) => void;
    onPageChange: (newPage: number) => void;
    currentPage : number;
    totalPages: number;
}) {
    const { currentUser } = useAuth(); // Assuming useAuth is availab
    const { client, setClient } = useClient();
    const { season } = useSeason();
    const { organization } = useOrganization();

    return (
        <Box>
            <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                    <Tr>
                        <Th>Status</Th>
                        <Th>Home</Th>
                        <Th>Home Score</Th>
                        <Th></Th>
                        <Th>Away</Th>
                        <Th>Away Score</Th>
                        <Th>Location</Th>
                        <Th>Date</Th>
                        <Th>Time</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {games?.map(game => (
                        <Tr key={game.id}>
                            <Td px={2} py={1}>
                                {game.status}
                            </Td>
                            <Td px={2} py={1}>
                                {game?.homeTeam ? game.homeTeam.name : "TBD"}
                            </Td>
                            <Td px={2} py={1}>
                                {game.homeTeamScore}
                            </Td>
                            <Td px={2} py={1}>
                                <LinkBox>
                                    <LinkOverlay onClick={() => onView(game.id)}
                                                 _hover={{ textDecoration: 'underline', color: 'blue.500' }}
                                                 cursor="pointer"
                                    >
                                        VS.
                                    </LinkOverlay>
                                </LinkBox>
                            </Td>
                            <Td px={2} py={1}>
                                {game?.awayTeam ? game.awayTeam.name : "TBD"}
                            </Td>
                            <Td px={2} py={1}>
                                {game.awayTeamScore}
                            </Td>
                            <Td px={2} py={1}>{game?.location?.name}</Td>
                            <Td px={2} py={1}>{game?.date ? format(parseISO(game.date), "MM/dd/yy") : 'TBD'}</Td>
                            <Td px={2} py={1}>{game?.time? convertTo12HourTime(game.time) : 'TBD'}</Td>
                            <Td px={2} py={1}>
                                <IconButton
                                    aria-label="Edit Game"
                                    icon={<EditIcon />}
                                    onClick={() => onEdit(game.id)}
                                    size="sm"
                                    variant="ghost" />
                                {game.status === 'INIT' ? (
                                        <IconButton
                                            aria-label="Show Score Sheet"
                                            icon={<TimeIcon />}  // Replace YourChosenIcon with the icon you want to use
                                            onClick={() => onShowScoreSheet(game.id)}
                                            size="sm"
                                            variant="ghost"
                                        />

                                ):(
                                    <IconButton
                                        aria-label="Initialize Game"
                                        icon={<StarIcon />}  // Replace YourChosenIcon with the icon you want to use
                                        onClick={() => onInitialize(game.id)}
                                        size="sm"
                                        variant="ghost"
                                    />
                                )}
                                <DeleteConfirmationIcon
                                    title="Delete Game"
                                    message="Are you sure you want to delete this game?"
                                    onDelete={() => onDelete(game.id)}
                                    label="Delete Game"
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Flex justifyContent="space-between" alignItems="center" mt="4">
                <Button onClick={() => onPageChange(currentPage - 1)} isDisabled={currentPage === 0}>Previous</Button>
                <Text>Page {currentPage + 1} of {totalPages}</Text>
                <Button onClick={() => onPageChange(currentPage + 1)} isDisabled={currentPage + 1 === totalPages}>Next</Button>
            </Flex>
        </Box>
    );
}

export default GameList;
