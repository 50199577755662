import { Flex, FormLabel, Link } from "@chakra-ui/react";
import { useSeasonYear } from "../hooks/SeasonYearContext";
import { useLocation } from "react-router-dom";

const buttonLinkStyles = {
    display: 'inline-block',
    px: '4',
    py: '2',
    borderRadius: 'md',
    textDecoration: 'none',
    borderStyle: 'solid',
    borderWidth: '1px'
};

const disabledStyle = {
    ...buttonLinkStyles,
    bg: 'white',
    color: 'gray.400',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    borderColor: 'gray.400'
};

const enabledStyle = {
    ...buttonLinkStyles,
    bg: 'blue.600',
    color: 'white',
    borderColor: 'transparent'
};

export const GenderLinks: React.FC<{ basePath: string }> = ({ basePath }) => {
    const { gender, setGender } = useSeasonYear();
    const location = useLocation();

    return (
        <FormLabel>
            Select Gender
            <Flex gap={2} alignItems="center">
                <Link
                    href={`${basePath}/men`}
                    onClick={(e) => {
                        e.preventDefault();
                        setGender('men');
                        window.history.pushState({}, '', `${basePath}/men`);
                    }}
                    {...(gender === "men" ? disabledStyle : enabledStyle)}
                >
                    Boys
                </Link>
                <Link
                    href={`${basePath}/women`}
                    onClick={(e) => {
                        e.preventDefault();
                        setGender('women');
                        window.history.pushState({}, '', `${basePath}/women`);
                    }}
                    {...(gender === "women" ? disabledStyle : enabledStyle)}
                >
                    Girls
                </Link>
            </Flex>
        </FormLabel>
    );
};

