// components/UserForm.tsx

import {Button, FormControl, FormLabel, Input, Box, Text, Select} from "@chakra-ui/react";
import React from "react";
import {useClient} from "../../Contexts/ClientContext";

import {Client, Organization} from "../../../utils/apiTypes";
import {useOrganization} from "../../Contexts/OrganizationContext";

type UserFormProps = {
    onSubmit: (user: UserData) => void;
    initialData?: UserData;
    onCancel: () => void;
};

type UserData = {
    id?: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    role: string;
    client: Client;
    organization: Organization| undefined
};

const UserForm: React.FC<UserFormProps> = ({ onSubmit, onCancel,  initialData }) => {
    const [email, setEmail] = React.useState(initialData?.email || "");
    const [firstName, setFirstName] = React.useState(initialData?.firstName || "");
    const [lastName, setLastName] = React.useState(initialData?.lastName || "");
    const [password, setPassword] = React.useState(initialData?.password || "");
    const [role, setRole] = React.useState(initialData?.role || "");
    const [validationError, setValidationError] = React.useState<string | null>(null);
    const { client } = useClient();
    const { organization  } = useOrganization();

    const roleOptions = {
        SuperAdmin: ["SuperAdmin", "Admin", "OrganizationAdmin", "User"],
        Admin: ["Admin", "OrganizationAdmin", "User"],
        OrganizationAdmin: ["OrganizationAdmin", "User"],
        User: ["User"],
    };
    const currentRole = localStorage.getItem("userRole")
    const allowedRoles = roleOptions[currentRole as keyof typeof roleOptions] || [];

    const validate = (): boolean => {
        if (!firstName.trim()) {
            setValidationError("First Name cannot be blank");
            return false;
        }
        if (!lastName.trim()) {
            setValidationError("Last Name cannot be blank");
            return false;
        }
        if (!email.trim()) {
            setValidationError("Email cannot be blank");
            return false;
        }
        if (!password.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        if (!role.trim()) {
            setValidationError("Name cannot be blank");
            return false;
        }
        setValidationError(null);
        return true;
    };

    const handleSubmit = () => {
        if (validate()) {
            const formData: UserData = {
                ...initialData,
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                role: role,
                client: client!,
                organization: organization!,
            };
            onSubmit(formData);
        }
    };

    return (
        <Box>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input value={firstName} onChange={e => setFirstName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input value={lastName} onChange={e => setLastName(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input value={email} onChange={e => setEmail(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    {validationError && <Text color="red.500">{validationError}</Text>}
                </FormControl>
                <FormControl id="role">
                    <FormLabel>Role</FormLabel>
                    <Select value={role} onChange={(e) => setRole(e.target.value)}>
                        {allowedRoles.map((roleOption) => (
                            <option key={roleOption} value={roleOption}>
                                {roleOption}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <Button mt={4} type="submit">
                    Submit
                </Button>
                <Button mt={4} onClick={onCancel} type="button">
                    Cancel
                </Button>
            </form>
        </Box>
    );
}

export default UserForm;
