import React, { useEffect, useState } from 'react';
import {Box, Container, Flex, Grid} from '@chakra-ui/react';
import TeamList from './components/TeamList'
import useApi from './hooks/useApi';
import {
    Heading
} from '@chakra-ui/react';
import { ITeamData } from './components/types';
import Pagination from './components/Pagination';
import useGenderParams from './hooks/userGenderParam';
import { GenderLinks } from './components/GenderLinks';
const TeamListPage = ({ ...props }) => {
    const { key: gender, title: genderTitle } = useGenderParams();
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const { data: teamData } = useApi<ITeamData>(`api/v1/client/10/teams?seasonId=${gender}&page=${page}&size=10&sort=name`);
    useEffect(() => {
        setPage(0);
    }, [gender])
    useEffect(() => {
        setIsLoading(true)
        if (teamData !== null) {
            setIsLoading(false)
        }
    }, [teamData])
    return (
        <Container {...props}>
            <Flex alignItems="center" justifyContent="space-between">
                <Heading size="md" padding="20px">Teams</Heading>
                <GenderLinks basePath='/teams' />
            </Flex>
            <Grid
                templateColumns={["1fr", "1fr 3fr 1fr"]}
                gap={6}
            >
                <Box gridColumn={["1 / -1", "1 / 2"]} p={4} order={[1, 0]}>
                    <a href="https://www.gipper.com/partner-demo-request?Partnerhnibbutton=&utm_source=button&utm_medium=Partner&utm_campaign=hnib&utm_content=partner&utm_term=hnib_button_masshockey_website_logo&query=Partnerhnibbutton"
                       target="_blank" rel="noopener noreferrer" style={{margin: '10px'}}>
                        <img width="300" height="300"
                             src="https://storage.googleapis.com/masshshockey2/images/ads/Gipper_MassHSHockey_300x300.png"
                             alt="Gipper"/>
                    </a>
                    <a href="https://uoduckshockey.com/recruits" target="_blank" rel="noopener noreferrer"
                       style={{margin: '10px'}}>
                        <img width="300" height="300"
                             src="https://storage.googleapis.com/masshshockey2/images/ads/UOREGON-HOCKEY-AD.jpg"
                             alt="Oregon"/>
                    </a>
                </Box>
                <Box gridColumn={["1 / -1", "2 / 3"]} p={4} order={[0, 0]} >
                    {/* Main Content */}
                    {/* Main Content */}
            {isLoading && (
                <>Loading</>
            )}
            {(!isLoading && teamData !== null) && (
                <>
                    <TeamList {...teamData} />
                    <Pagination currentPage={page} totalPages={teamData.totalPages} onPageChange={setPage} />
                </>
            )}
                </Box>
                <Box gridColumn={["1 / -1", "3 / 4"]} p={4} order={[1, 0]}>
                    <a href="https://laurastamm.com/" target="_blank" rel="noopener noreferrer"
                       style={{margin: '10px', verticalAlign: 'top'}}>
                        <img width="300" height="300"
                             src="https://storage.googleapis.com/masshshockey2/images/ads/Laura-Stamm_2021_300px.jpg"
                             alt="Laura Stamm"/>
                    </a>
                    <a href="https://nswingsgirls.com/" target="_blank" rel="noopener noreferrer"
                       style={{margin: '10px', verticalAlign: 'top'}}>
                        <img width="300" height="300"
                             src="https://storage.googleapis.com/masshshockey2/images/ads/NS_Wings_Girls_2023AD-1.png"
                             alt="NS Wings"/>
                    </a>
                    <a href="http://dynamiksports.com/" target="_blank" rel="noopener noreferrer"
                       style={{margin: '10px', verticalAlign: 'top'}}>
                        <img width="300" height="300"
                             src="https://storage.googleapis.com/masshshockey2/images/ads/Dynamik-300x250.jpg"
                             alt="Dynamic Sports"/>
                    </a>
                </Box>
            </Grid>
        </Container>
    );
};

export default TeamListPage;
