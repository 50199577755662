import {Client, Organization, Season} from "./apiTypes";

export function teamsQueryMap(client: Client | null | undefined, organization: Organization | null | undefined, season: Season | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (organization) {
        queryMap.organizationId = organization.id;
    }

    if (season) {
        queryMap.seasonId = season.id;
    }

    return queryMap;
}

export function teamsUploadMap(client: Client | null | undefined, organization: Organization | null | undefined ): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (organization) {
        queryMap.organizationId = organization.id;
    }

    return queryMap;
}

export function teamGroupsUploadMap(client: Client | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    return queryMap;
}

export function teamGroupsQueryMap(client: Client | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    return queryMap;
}


export function seasonsQueryMap(client: Client | null | undefined, organization: Organization | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (organization) {
        queryMap.organizationId = organization.id;
    }

    return queryMap;
}

export function locationsQueryMap(client: Client | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    return queryMap;
}

export function usersQueryMap(client: Client | null | undefined, organization: Organization | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (organization) {
        queryMap.organizationId = organization.id;
    }

    return queryMap;
}

export function gamesQueryMap(client: Client | null | undefined, organization: Organization | null | undefined, season: Season | null | undefined): Record<string, string[] | string | number | boolean> {
    const queryMap: Record<string, string[] | string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (organization) {
        queryMap.organizationId = organization.id;
    }

    if (season) {
        queryMap.seasonId = season.id;
    }

    return queryMap;
}

export function organizationsQueryMap(client: Client | null | undefined, season: Season | null | undefined): Record<string, string | number | boolean> {
    const queryMap: Record<string, string | number | boolean> = {};

    if (client) {
        queryMap.clientId = client.id;
    }

    if (season) {
        queryMap.seasonId = season.id;
    }

    queryMap.sort = 'name';

    return queryMap;
}